import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useApi } from 'common/hooks/api';

export const useUpdateDmcOverrideLine = () => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: async payload => (await Api.updateDmcOverrideLine(payload))?.data,
    onError: err => enqueueSnackbar(err?.response?.data?.error?.message, { variant: 'error', style: { whiteSpace: 'pre-line' } }),
  });
  return mutation;
};
