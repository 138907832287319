import { cloneDeep, defaultsDeep, merge } from 'lodash';

class Model {
  constructor(data = {}, defaults = {}) {
    merge(this, defaultsDeep(data, defaults));
    Object.defineProperty(this, '_dataValues', {
      value: cloneDeep(data),
      enumerable: false,
    });
    this._cache = {};
  }
  toJSON() {
    this.clearCache();
    return cloneDeep(this._dataValues);
  }
  set(key, value) {
    if (key in this._dataValues) {
      this._dataValues[key] = value;
      this[key] = value;
    }
  }
  _getCachedValue(key, fn) {
    if (!(key in this._cache)) {
      this._cache[key] = fn();
    }
    return this._cache[key];
  }

  clearCacheKey(key) {
    delete this._cache[key];
  }

  clearCache() {
    this._cache = {};
  }
}

export default Model;
