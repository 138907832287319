import { forwardRef } from 'react';

export default forwardRef(function HeartMark({ className }, ref) {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" className={className} ref={ref} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.72607 1H15.7261V19L8.72607 15.9143L1.72607 18.8138V1Z" fill="white" fillOpacity="0.6" />
      <path
        d="M8.50375 12.5557C9.44818 11.6853 10.2121 10.9677 10.7954 10.4029C11.3787 9.8381 11.8278 9.352 12.1426 8.9446C12.4574 8.5372 12.6704 8.18072 12.7815 7.87517C12.8926 7.56962 12.9481 7.24092 12.9481 6.88907C12.9481 6.22241 12.7074 5.64835 12.2259 5.16687C11.7444 4.6854 11.1704 4.44466 10.5037 4.44466C10.1148 4.44466 9.73984 4.52336 9.37873 4.68077C9.01763 4.83817 8.72596 5.05576 8.50375 5.33354C8.28153 5.05576 7.99449 4.83817 7.64265 4.68077C7.2908 4.52336 6.91118 4.44466 6.50377 4.44466C5.83712 4.44466 5.26305 4.6854 4.78158 5.16687C4.3001 5.64835 4.05936 6.22241 4.05936 6.88907C4.05936 7.24092 4.11029 7.56499 4.21214 7.86128C4.31399 8.15757 4.52232 8.50942 4.83713 8.91682C5.15194 9.32422 5.59638 9.81033 6.17044 10.3751C6.74451 10.9399 7.52228 11.6668 8.50375 12.5557ZM0.726076 20V2.22247C0.726076 1.61137 0.726074 0.555413 0.726074 8.91114e-05C1.28162 0 2.33716 0.000278569 2.94827 0.000278569H14.0592C14.6703 0.000278569 15.7259 -4.4879e-05 16.2814 5.28524e-06C16.2814 0.555351 16.2814 1.61137 16.2814 2.22247V20L8.50375 16.6667L0.726076 20ZM1.72607 18.5L8.50375 15.5L15.2261 18.5L15.2261 1H1.72607L1.72607 18.5Z"
        fill="#2F5053"
        fillOpacity="0.95"
      />
    </svg>
  );
});
