import { values } from 'lodash';
import * as yup from 'yup';
import { ITINERARY_ORIGIN } from './itinerary-origin';
import logger from 'itrvl-logger';
import { ITINERARY_STATE } from './itineraryState';
const log = logger(__filename);

/**
 * @enum {string}
 * @description Represents the different types of itinerary events.
 */
export const ITINERARY_EVENT = {
  // originating events
  QUOTE_CREATED: 'quote_created',
  QUOTE_IMPORTED: 'quote_imported',
  COPIED_TO_BUILDER: 'copied_to_builder',
  QUOTE_DUPLICATED: 'quote_duplicated',
  RESTORED_VERSION: 'restored_version',
  //
  SEGMENTS_MODIFIED: 'segments_modified',
  INVOICE_GENERATED: 'invoice_generated',
  INVOICE_PAID: 'invoice_paid',
  INVOICE_MARKED_AS_PAID: 'invoice_marked_as_paid',
  INVOICE_UPDATED: 'invoice_updated',
  STATUS_CHANGED: 'status_changed',
  ACTIVITIES_EDITED: 'activities_edited',
  TRANSIT_EDITED: 'transit_edited',
  ACCOMMODATION_MODIFIED: 'accommodation_modified',
  DMC_COST_ADDED: 'dmc_cost_added',
};

export function isValidItineraryEventType(type) {
  return values(ITINERARY_EVENT).includes(type);
}

const DATE_FORMAT = /^\d{4}-\d{2}-\d{2}$/;

const dateString = yup
  .string()
  .matches(DATE_FORMAT, ({ path }) => `${path} must be in YYYY-MM-DD format`)
  .required();

const nonNegativeNumber = yup.number().min(0, ({ path }) => `${path} must be greater than or equal to 0`);

// @todo: enforce itinerary shape?
const itinerarySchema = yup.object();

const roomData = yup.object().shape({
  roomName: yup.string().required(),
  // @todo: shape
  travelers: yup.object().required(),
  rateKey: yup.string().required(),
});

const originatingSchema = yup.object().shape({
  bookingRef: yup.string().required(),
  itineraryId: yup.string().required(),
  currency: yup.string().required(),
  nights: nonNegativeNumber.required(),
  name: yup.string().required(),
  startDate: dateString,
  endDate: dateString,
  adults: nonNegativeNumber.required(),
  children: nonNegativeNumber.required(),
  totalSell: nonNegativeNumber.required(),
  supplierCost: nonNegativeNumber.required(),
  margin: yup.number().required(),
  sellingPrice: nonNegativeNumber.required(),
  status: yup.string(),
});

const originatingHistoricalSchema = originatingSchema.shape({
  prevBookingRef: yup.string(),
  prevItineraryId: yup.string(),
});

const originatingRestoreSchema = originatingHistoricalSchema.shape({
  eventDate: yup.date().required(),
});

const statusChangedSchema = originatingSchema.shape({
  status: yup.string().required(),
  previousStatus: yup.string().required(),
});

const statusChangedWithInvoicesSchema = statusChangedSchema.shape({
  invoiceTotal: nonNegativeNumber.required(),
});

const invoiceGeneratedSchema = originatingSchema.shape({
  invoiceTotal: nonNegativeNumber.required(),
  percentInvoiced: nonNegativeNumber.required(),
});

const invoicePaidSchema = originatingSchema.shape({
  percentInvoiced: nonNegativeNumber.required(),
  clientName: yup.string().required(),
  // storing for potential lookups
  paymentId: yup.string().required(),
  paymentType: yup.string().required(),
  // payment status
  status: yup.string().required(),
  amount: nonNegativeNumber.required(),
  serviceFee: yup.number().required(),
  feeDiscount: yup.number().required(),
  last4: yup.string(),
});

const activitiesEditedSchema = originatingSchema.shape({
  // @todo: enforce an array of segments?
  added: yup.array().required(),
  updated: yup.array().required(),
  deleted: yup.array().required(),
  previous: itinerarySchema,
  percentInvoiced: nonNegativeNumber,
  hasPaid: yup.boolean(),
});

const accommodationModifiedSchema = yup.object().shape({
  name: yup.string().required(),
  startDate: dateString,
  endDate: dateString,
  costAmount: nonNegativeNumber.required(),
  rspAmount: nonNegativeNumber.required(),
  sellAmount: nonNegativeNumber.required(),
  country: yup.string().required(),
  region: yup.string().required(),
  nights: yup.string().required(),
  sellingPrice: yup
    .array()
    .required()
    .length(2)
    .of(nonNegativeNumber.required()),
  roomData: yup
    .array()
    .required()
    .of(roomData),
  previous: itinerarySchema,
  percentInvoiced: nonNegativeNumber,
  hasPaid: yup.boolean(),
  bednight: yup.boolean(),
});

const dmcSchema = yup.object().shape({
  name: yup.string().required(),
  currency: yup.string().required(),
  status: yup.string().required(),
  costAmount: nonNegativeNumber.required(),
  sellAmount: nonNegativeNumber.required(),
  rspAmount: nonNegativeNumber.required(),
  sellingPrice: nonNegativeNumber.required(),
  previous: itinerarySchema,
  percentInvoiced: nonNegativeNumber,
  hasPaid: yup.boolean(),
});

export function originForType(type) {
  const types = {
    [ITINERARY_EVENT.QUOTE_CREATED]: ITINERARY_ORIGIN.IB,
    [ITINERARY_EVENT.COPIED_TO_BUILDER]: ITINERARY_ORIGIN.IB,
    [ITINERARY_EVENT.QUOTE_DUPLICATED]: ITINERARY_ORIGIN.DUPLICATE,
    [ITINERARY_EVENT.RESTORED_VERSION]: ITINERARY_ORIGIN.RESTORE,
    [ITINERARY_EVENT.QUOTE_IMPORTED]: ITINERARY_ORIGIN.IMPORT,
  };
  return types[type];
}

export function shouldEventIncludeInvoices(status) {
  return [ITINERARY_STATE.CONFIRM_REQUESTED, ITINERARY_STATE.CONFIRMED, ITINERARY_STATE.CANCELLED].includes(status);
}

export async function validateItineraryEvent(type, data) {
  log.debug('validateItineraryEvent', type, data);
  let schema;
  switch (type) {
    case ITINERARY_EVENT.QUOTE_CREATED:
    case ITINERARY_EVENT.QUOTE_IMPORTED:
      schema = originatingSchema;
      break;
    case ITINERARY_EVENT.COPIED_TO_BUILDER:
    case ITINERARY_EVENT.QUOTE_DUPLICATED:
      schema = originatingHistoricalSchema;
      break;
    case ITINERARY_EVENT.RESTORED_VERSION:
      schema = originatingRestoreSchema;
      break;
    case ITINERARY_EVENT.STATUS_CHANGED:
      if (shouldEventIncludeInvoices(data.status)) {
        schema = statusChangedWithInvoicesSchema;
      } else {
        schema = statusChangedSchema;
      }

      break;
    case ITINERARY_EVENT.INVOICE_UPDATED:
    case ITINERARY_EVENT.INVOICE_GENERATED:
      schema = invoiceGeneratedSchema;
      break;
    case ITINERARY_EVENT.INVOICE_PAID:
    case ITINERARY_EVENT.INVOICE_MARKED_AS_PAID:
      schema = invoicePaidSchema;
      break;
    case ITINERARY_EVENT.DMC_COST_ADDED:
      schema = dmcSchema;
      break;
    case ITINERARY_EVENT.ACCOMMODATION_MODIFIED:
      schema = accommodationModifiedSchema;
      break;
    case ITINERARY_EVENT.SEGMENTS_MODIFIED:
    case ITINERARY_EVENT.TRANSIT_EDITED:
    case ITINERARY_EVENT.ACTIVITIES_EDITED:
      schema = activitiesEditedSchema;
      break;
    default:
      log.warn('schema not found for event type', type);
      throw new Error(`schema not found for event type ${type}`);
  }

  return schema.validate(data, { abortEarly: false });
}
