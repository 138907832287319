import { useMutation } from '@tanstack/react-query';
import { cloneDeep, isArray, merge, mergeWith } from 'lodash';

import { useApi } from 'common/hooks/api';
import { useSnackbar } from 'notistack';
import { saveAs } from 'common/utils/file-saver';
import { useUser } from 'common/hooks/user';
import { queryClient } from 'query-client';
import { AUDIT_TRAIL_ACTIONS, ITINERARY_FLAG, setItineraryFlag } from 'itrvl-types';
import Itinerary from 'models/Itinerary';
import { useFetchItinerary } from 'api';

function customizer(objValue, srcValue) {
  // if both paths are an array, prefer the latter as the source of truth
  if (isArray(objValue) && isArray(srcValue)) {
    return srcValue;
  }
}

const includeKeys = [['agent'], ['client'], ['payments', []], ['parties', []], ['messages', []]];
export const makeDefaultSuccessHandler = (props = {}) => (data = {}) => {
  const { id } = data;
  const prev = queryClient.getQueryData(['itinerary', id]);

  // most itineraries returned don't includes so copy these over
  const cache = {};
  includeKeys.forEach(([key, defaultValue]) => {
    cache[key] = data[key] ?? prev?._dataValues[key] ?? defaultValue;
  });

  const newInstance = mergeWith({}, data, cache, customizer);

  queryClient.setQueryData(['itinerary', id], new Itinerary(newInstance));
  props.onSuccess && props.onSuccess();
};

export const useLogAudit = outerProps => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async callSiteProps =>
      Api.logAudit({
        ...outerProps,
        ...callSiteProps,
      }),
  });
  return mutation;
};

// @todo: we should handle this on the API if we want it to happen on every call
export const useAgentAccessLogAudit = () => {
  const userContext = useUser();
  const mutation = useLogAudit({
    action: AUDIT_TRAIL_ACTIONS.AGENT_ACCESSED_ITINERARY,
    agentId: userContext?.user?.userId,
  });
  return mutation;
};

export const useUpdateItinerary = (props = {}) => {
  const Api = useApi();
  const logAuditMutation = useAgentAccessLogAudit();
  const mutation = useMutation({
    mutationFn: async ({ id, key, value }) => {
      const response = await Api.itineraryPatch(id, key, value);
      logAuditMutation.mutate({ itineraryId: id });
      return response?.data;
    },
    onSuccess: (data, { id }) => {
      //   PATCH doesn't return the correct related data, so we'll just merge the result with what we already have
      const prev = queryClient.getQueryData(['itinerary', id]);
      if (prev) {
        queryClient.setQueryData(['itinerary', id], new Itinerary(merge(prev._dataValues, data)));
      }
      props.onSuccess?.(data);
    },
  });
  return mutation;
};

export const useAddAccommodationDays = props => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ itineraryId, newNumberOfNights, moveDatesDown, ffKey }) => {
      const response = await Api.addAccommodationDays(itineraryId, newNumberOfNights, moveDatesDown, ffKey);
      return response;
    },
    onSuccess: (data, { itineraryId }) => {
      queryClient.setQueryData(['itinerary', itineraryId], new Itinerary(data));
      props.onSuccess?.();
    },
  });
  return mutation;
};

export const useItineraryUpdateAndRequote = props => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ itineraryId, itinerary }) => {
      const response = await Api.itineraryUpdateAndRequote(itineraryId, itinerary);
      return response?.data;
    },
    onSuccess: (data, { itineraryId }) => {
      queryClient.setQueryData(['itinerary', itineraryId], new Itinerary(data));
      props.onSuccess?.();
    },
  });
  return mutation;
};

export const useUpdateItinerarySilently = () => {
  const Api = useApi();
  return useMutation({
    mutationFn: async ({ id, key, value }) => (await Api.itineraryPatch(id, key, value))?.data,
  });
};

export const useUpdateItineraryOptimistically = props => {
  const Api = useApi();
  const logAuditMutation = useAgentAccessLogAudit();
  const { enqueueSnackbar } = useSnackbar();
  let cachedValue = {};
  const mutation = useMutation({
    mutationFn: async ({ id, key, value }) => {
      const prev = queryClient.getQueryData(['itinerary', id]);
      cachedValue = { id, [key]: prev[key] };
      queryClient.setQueryData(['itinerary', id], new Itinerary(merge(prev._dataValues, { [key]: value })));
      logAuditMutation.mutate({ itineraryId: id });
      const response = await Api.itineraryPatch(id, key, value);
      return response?.data;
    },
    onSuccess: data => {
      props?.onSuccess && props.onSuccess(data);
      cachedValue = {};
    },
    onError: (_err, { id }) => {
      enqueueSnackbar('Unable to update itinerary', {
        variant: 'error',
      });
      const prev = queryClient.getQueryData(['itinerary', id]);
      queryClient.setQueryData(['itinerary', id], new Itinerary(merge(prev._dataValues, { ...cachedValue })));
      cachedValue = {};
    },
  });
  return mutation;
};

export const useReplaceItineraryOptimistically = props => {
  const { id } = props;
  const Api = useApi();
  const logAuditMutation = useAgentAccessLogAudit();
  let cachedValue = {};
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: async data => {
      const prev = queryClient.getQueryData(['itinerary', id]);
      cachedValue = merge({}, prev._dataValues);
      const instance = new Itinerary(merge(prev._dataValues, data));
      queryClient.setQueryData(['itinerary', id], instance);
      logAuditMutation.mutate({ itineraryId: id });
      const response = await Api.itineraryUpdate(instance);
      return response?.data;
    },
    onSuccess: data => {
      props?.onSuccess && props.onSuccess(data);
      cachedValue = {};
    },
    onError: () => {
      enqueueSnackbar('Unable to update itinerary', {
        variant: 'error',
      });
      const prev = queryClient.getQueryData(['itinerary', id]);
      queryClient.setQueryData(['itinerary', id], new Itinerary(merge(prev._dataValues, cachedValue)));
      cachedValue = {};
    },
  });
  return mutation;
};

export const useReplaceItinerary = props => {
  const { id } = props;
  const Api = useApi();
  const logAuditMutation = useAgentAccessLogAudit();
  const mutation = useMutation({
    mutationFn: async data => {
      const prev = queryClient.getQueryData(['itinerary', id]);
      const instance = merge(prev._dataValues, data);
      logAuditMutation.mutate({ itineraryId: id });
      const response = await Api.itineraryUpdate(instance);
      return response?.data;
    },
    onSuccess: data => {
      const prev = queryClient.getQueryData(['itinerary', id]);
      queryClient.setQueryData(['itinerary', id], new Itinerary(merge(prev._dataValues, data)));
      props.onSuccess && props.onSuccess();
    },
  });
  return mutation;
};

const useRequestHoldZap = () => {
  const Api = useApi();
  const userContext = useUser();
  const mutation = useMutation({
    mutationFn: async ({ instance }) => {
      Api.zap('holdRequested', userContext, { quoteId: instance.quoteId }, window?.location?.href);
    },
  });
  return mutation;
};

export const useRequestHold = props => {
  const Api = useApi();
  const requestHoldZap = useRequestHoldZap();
  const mutation = useMutation({
    mutationFn: async ({ instance, message }) => {
      requestHoldZap.mutate({ instance });
      const response = await Api.itineraryHold(instance.id, undefined, message);
      return response?.data;
    },
    onSuccess: makeDefaultSuccessHandler(props),
  });
  return mutation;
};

// @todo: deprecate?
export const useGenerateInvoices = props => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ instance }) => {
      const response = await Api.itineraryGenerateInvoicesWithDepositRate(instance.id, instance.depositRate);
      return response?.data;
    },
    onSuccess: makeDefaultSuccessHandler(props),
  });

  return mutation;
};

export const useClearInvoices = props => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ instance }) => {
      const response = await Api.itineraryClearInvoices(instance.id, instance);
      return response?.data;
    },
    onSuccess: makeDefaultSuccessHandler(props),
  });
  return mutation;
};

export const useUpdateItineraryCurrency = props => {
  const Api = useApi();
  const { id } = props;
  const mutation = useMutation({
    mutationFn: async data => {
      const response = await Api.updateItineraryCurrency(id, data.currency);
      return response?.data;
    },
    onSuccess: data => {
      const prev = queryClient.getQueryData(['itinerary', id]);
      queryClient.setQueryData(['itinerary', id], new Itinerary(merge(prev._dataValues, data)));
      props.onSuccess && props.onSuccess();
    },
  });
  return mutation;
};

export const useDownloadPdfItinerary = () => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: async ({ itinerary }) => {
      const response = await Api.downloadItineraryLocation(itinerary.id);
      const { data } = response;
      if (!data || !data.location) {
        throw new Error('something went wrong');
      }
      return data;
    },
    onSuccess: (data, { itinerary }) => {
      return saveAs(data.location, `${itinerary.name} - Detailed Itinerary.pdf`);
    },
    onError: () => enqueueSnackbar(`PDF document download failure`, { variant: 'error' }),
  });
  return mutation;
};

export const useDownloadConfirmationInvoice = () => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: async ({ itinerary }) => {
      const response = await Api.editItinerary.downloadConfirmationInvoice(itinerary.id);
      return {
        data: response?.data,
        itinerary,
      };
    },
    onSuccess: ({ data, itinerary }) => {
      saveAs(data, `${itinerary.name}-${itinerary.id}.pdf`);
    },
    onError: () => enqueueSnackbar(`Booking document download failure`, { variant: 'error' }),
  });
  return mutation;
};

export const useDownloadPricingFile = () => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: async ({ itinerary }) => {
      const response = await Api.downloadPricingCsv(itinerary.id);
      return {
        data: response?.data,
        itinerary,
      };
    },
    onSuccess: ({ data, itinerary }) => {
      saveAs(data, `${itinerary.name}.csv`);
    },
    onError: () => enqueueSnackbar(`Pricing file download failure`, { variant: 'error' }),
  });
  return mutation;
};

export const useConfirmNegativeMargin = () => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ itinerary }) => {
      const { id } = itinerary;

      const prev = queryClient.getQueryData(['itinerary', id]);
      const clone = cloneDeep(prev._dataValues);

      setItineraryFlag(clone, ITINERARY_FLAG.SUPPRESS_PROBLEM_NEGATIVE_MARGIN, true);

      const response = await Api.itineraryUpdate(clone);
      return response?.data;
    },
    onSuccess: (data, { onSuccess }) => makeDefaultSuccessHandler({ onSuccess })(data),
  });
  return mutation;
};

export const useSendConsultantEmail = () => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: async ({ data: { id, conversation, message, conversationTitle } }) => {
      await Api.itinerarySendConsultantEmail(id, conversation, message, conversationTitle);
      // itinerarySendConsultantEmail does not return a message, so we need to refetch all
      // we cannot do things like select most recently commented conversation due to this
      const messages = await Api.getMessages(id);

      return {
        messages: messages?.data,
        id,
      };
    },
    onSuccess: ({ id, messages }) => {
      const prev = queryClient.getQueryData(['itinerary', id]);
      if (prev) {
        const clone = cloneDeep(prev._dataValues);
        clone.messages = messages;
        queryClient.setQueryData(['itinerary', id], new Itinerary(clone));
      }
    },
    onError: () => {
      enqueueSnackbar(`Unable to send consultant email`, { variant: 'error' });
    },
  });
  return mutation;
};

export const useUpdateMessages = () => {
  const Api = useApi();
  const mutation = useMutation({
    mutationFn: async ({ itineraryId, messageIds = [] }) => {
      await Promise.all(messageIds.map(id => Api.updateMessage(id, { read: true })));
      const messages = await Api.getMessages(itineraryId);

      return {
        itineraryId,
        messages: messages?.data,
      };
    },
    onSuccess: ({ itineraryId, messages }) => {
      const prev = queryClient.getQueryData(['itinerary', itineraryId]);
      const clone = cloneDeep(prev._dataValues);
      clone.messages = messages;
      queryClient.setQueryData(['itinerary', itineraryId], new Itinerary(clone));
    },
  });
  return mutation;
};

export const useRefetchItinerary = () => {
  const fetchItinerary = useFetchItinerary();
  const mutation = useMutation({
    mutationFn: async ({ id }) => {
      const response = await fetchItinerary.fetch(id);
      return response;
    },
    onSuccess: data => queryClient.setQueryData(['itinerary', data.id], data),
  });
  return mutation;
};
