import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { useUser } from 'common/hooks/user';
import { getAgencyIdForPatch } from './utils';
const CACHE_KEYS = {
  REGIONS: 'regions',
  SEARCH: 'search',
  LIST: 'list',
};

export function useRegionsSearchPagedQuery({ term, countryCode, enabled = true, pageSize = 50 }) {
  const Api = useApi();
  return useInfiniteQuery({
    queryKey: [CACHE_KEYS.REGIONS, CACHE_KEYS.SEARCH, { term, countryCode }],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await Api.regionSearch({
        limit: pageSize,
        skip: pageParam * pageSize,
        term,
        countryCode,
      });
      return response?.data;
    },
    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined;
    },
    enabled,
  });
}

export const useRegionsListQuery = ({ filters = {}, enabled = true }) => {
  const Api = useApi();
  return useQuery({
    queryKey: [CACHE_KEYS.REGIONS, CACHE_KEYS.LIST, filters],
    queryFn: async () => {
      const response = await Api.getAgencyRegionsList(filters);
      return response?.data ?? [];
    },
    retry: (_failureCount, error) => {
      if (error?.response?.status >= 400) return false;
      return 3;
    },
    cacheTime: 0,
    enabled,
    keepPreviousData: true,
  });
};

export const useRegionsInfoEditsUpsert = props => {
  const Api = useApi();
  const { user } = useUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      const response = await Api.upsertRegionEdits(id, {
        ...payload,
        agencyId: getAgencyIdForPatch(user),
      });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.REGIONS, CACHE_KEYS.LIST],
      });
      return response?.data || [];
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
};
export const useRegionsEditsPatch = props => {
  const Api = useApi();
  const { user } = useUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      const response = await Api.patchRegionEdits(id, {
        ...payload,
        agencyId: getAgencyIdForPatch(user),
      });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.REGIONS, CACHE_KEYS.LIST],
      });
      return response?.data || [];
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
};
