export const DOCUMENT = {
  MAX_FILE_SIZE: 50 * 1024 * 1024, // 50 MB
  EXPIRY_SECONDS: 24 * 60 * 60,
  STATE: {
    READY: 'ready',
    COMPLETE: 'complete',
  },
  ALLOWED_RELATED_TYPES: ['ItineraryEvent'],
  ALLOWED_MIME_TYPES: [
    // Text Documents
    'text/plain', // Plain Text
    'application/rtf', // Rich Text Format
    // Word Processing Documents
    'application/msword', // Microsoft Word (.doc)
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word (.docx)
    'application/vnd.oasis.opendocument.text', // OpenDocument Text (.odt)
    // Spreadsheets
    'application/vnd.ms-excel', // Microsoft Excel (.xls)
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Microsoft Excel (.xlsx)
    'application/vnd.oasis.opendocument.spreadsheet', // OpenDocument Spreadsheet (.ods)
    'text/csv', // CSV
    // Presentations
    'application/vnd.ms-powerpoint', // Microsoft PowerPoint (.ppt)
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // Microsoft PowerPoint (.pptx)
    'application/vnd.oasis.opendocument.presentation', // OpenDocument Presentation (.odp)
    // PDFs
    'application/pdf', // Portable Document Format
    // Images, allow all
    'image/*',
    // @todo: do we want archives?
    // Archives
    'application/zip', // ZIP Archives
    'application/x-7z-compressed', // 7-Zip Archives
    'application/x-rar-compressed', // RAR Archives
    // Markup and Code Files (if applicable)
    'text/html', // HTML
    'text/markdown', // Markdown
    'application/json', // JSON
    'application/xml', // XML
  ],
};
