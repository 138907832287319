import { useGetDmcByCountryCurrencyQuery } from 'api';

export const useGetDmcByCountryCurrency = ({ countryCode, currency, onSuccess }) => {
  const query = useGetDmcByCountryCurrencyQuery({ countryCode, currency, onSuccess });
  let dmcList = [];
  if (query.isFetched) {
    dmcList = query?.data;
  }
  return {
    ...query,
    dmcList,
  };
};
