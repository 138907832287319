import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import Loader from './Loader';
import ListCard from './ListCard';
import InfiniteLoader from './InfiniteLoader';
import GridViewRow from './GridViewRow';
import { useBuilderStore } from './store';
import { useAccommodationsListQuery } from './queries';
import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);
const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: props => (props.viewMode === 'grid' ? 10 : 23),
    },
    card: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      alignItems: 'stretch',
    },
    grid: {
      gap: 20,
    },
    loader: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
    },
  },
  {
    name: 'AccommodationsList',
  },
);

export const ownAccommodationProps = {
  campFeatures: [],
  campInfo:
    'When adding an own-arranged accommodation, you will need to provide a title for the accommodation and select location details. Once you have generated a quote, you can customize the accommodation details in Edit in Presentation Editor.',
  label: 'Own Accommodation',
  campName: 'Own Accommodation',
  consultantInteractionRequired: false,
  country: 'TBD',
  description:
    'When adding an own-arranged accommodation, you will need to provide a title for the accommodation and select location details. Once you have generated a quote, you can customize the accommodation details in Edit in Presentation Editor.',
  dmcArrangedOnly: false,
  online: false,
  regionName: 'TBD',
  supplierCode: 'OWN',
  restOfWorld: false,
  ownAccommodation: true,
};

const AccommodationsList = ({ dates = [] }) => {
  const viewMode = useBuilderStore(state => state.data.viewMode);
  const classes = useStyles({ viewMode });
  const term = useBuilderStore(state => state.ui.accommodations.term);

  const accommodationsListQuery = useAccommodationsListQuery();
  const { isLoading, data, hasNextPage, fetchNextPage } = accommodationsListQuery;

  const termMatchesOwnAccommodation = ownAccommodationProps.campName.toLowerCase().includes(term.toLowerCase());

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  // @todo: maybe memo?
  const items = isLoading ? [] : data?.pages?.flatMap(o => o.data);
  if (!isLoading && ((term && termMatchesOwnAccommodation) || data.pages?.[0].data.length === 0)) {
    items.unshift(ownAccommodationProps);
  }

  return (
    <div data-test-id="accommodations-list" className={clsx(classes.root, classes[viewMode])} /*ref={parent}*/>
      {isLoading && <Loader text="Finding Accommodations" />}
      {!isLoading && (
        <>
          {items.map(camp => {
            return viewMode === 'grid' ? (
              <GridViewRow key={camp.supplierCode} camp={camp} dates={dates} />
            ) : (
              <ListCard key={camp.supplierCode} item={camp} {...camp} label={camp.campName} description={camp.campInfo} />
            );
          })}
          {hasNextPage && <InfiniteLoader ref={ref} />}
        </>
      )}
    </div>
  );
};

export default AccommodationsList;
