import React, { Component } from 'react';
import { flushSync } from 'react-dom';
import { adaptV4Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { withRouter } from 'react-router-dom';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import { reduce, assign, set, merge, get, debounce, filter } from 'lodash';
import { ModalProvider } from 'react-modal-hook';
import logger from 'itrvl-logger';
import { getAgency } from 'itrvl-types';
import NiceModal from '@ebay/nice-modal-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'query-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalStyles from '@mui/material/GlobalStyles';

import { createTheme } from '@mui/material/styles';
import moment from 'moment';

import './App.css';

import FeatureFlags from './FeatureFlags';
import { ThemeProvider } from 'common';
import { getCookieOptions } from 'common/utils/cookie';
import { AGENT_THEME_DEFAULTS } from 'common/constants/theme';
import Loading from 'common/components/Loading';
import FeatureProvider from './components/FeatureProvider';

import Cookies from 'universal-cookie';
import Api from './utils/Api';

import { UserContext } from 'common/context/UserContext';
import { ItinerariesContext } from 'common/context/ItinerariesContext';
import { LodgeMappingContext } from 'common/context/LodgeMappingContext';
import ApiContext from 'common/context/ApiContext';
import { CurrencyContextProvider } from './context/Currency';
import * as Sentry from '@sentry/react';
import Freshchat from './Freshchat';
import { ItineraryBuilderStoreProvider } from 'components/ItineraryBuilder/store';

const log = logger(__filename);

const withThemeProps = initialWidth => {
  let themeProps = {};
  if (initialWidth) {
    themeProps = {
      props: {
        MuiWithWidth: {
          initialWidth,
        },
      },
    };
  }
  return themeProps;
};

const buildThemeWithProps = (defaults, initialWidth) => createTheme(adaptV4Theme(merge(withThemeProps(initialWidth), defaults)));

const styles = {};

export class App extends Component {
  state = {
    user: null,
    client: null,
    itineraries: [],
    lodgeMapping: null,
    exchange: null,
    renderReady: false,
    theme: buildThemeWithProps(AGENT_THEME_DEFAULTS),
    customizations: {},
    clients: [],
  };

  apiPatchClient = debounce(patch => {
    this.Api.patchClient(this.state.client.id, patch);
  }, 500);

  patchClient = debounce((patch, apiPatch = true) => {
    apiPatch && this.apiPatchClient(patch);
  }, 500);

  updateClient = async client => {
    await this.refreshClients(); // Do before refreshClient.
    await this.refreshClient(client.id);
    await this.refreshItineraries(client.id);
  };

  masquerade = async (token, endTime) => {
    log.trace('masquerade', token);
    const masqFromToken = this.cookies.get('TOKEN');
    if (token) {
      const originalUser = get(this.state.user, 'email');

      this.cookies.set('MASQ', token, getCookieOptions(endTime / 1000));
      this.cookies.set('MASQ_FROM', masqFromToken, getCookieOptions(endTime / 1000));
      // Store timeout provided
      if (endTime) {
        this.cookies.set('MASQ_END', +endTime / 1000, getCookieOptions(endTime / 1000));
      }
      this.Api._masquerade(token, masqFromToken);
      await this.setupUser(this.getCurrentToken());
      const masqueradeUser = get(this.state.user, 'email');
      Sentry.configureScope(scope => {
        scope.setUser({ email: `[${originalUser}] -> ${masqueradeUser}` });
        scope.setTag('agencyCode', get(this.state.user, 'agency.agencyCode'));
        scope.setTag('masquerade', true);
      });
    } else {
      log.trace('removing MASQ cookie');
      const originalToken = this.cookies.get('MASQ_FROM');
      this.cookies.remove('MASQ');
      this.cookies.remove('MASQ_END');
      this.cookies.remove('MASQ_FROM');
      this.cookies.remove('CLIENT_ID');
      this.cookies.set('TOKEN', originalToken);
      this.Api._masquerade();
      this.Api._updateInstanceToken(originalToken);
      Sentry.configureScope(scope => {
        scope.setTag('masquerade', undefined);
        scope.setTag('agencyCode', null);
      });

      // Clear selected client when masquerade ends
      this.setState({ client: null });
      this.updateUser(originalToken, undefined);
    }
  };

  isMasquerading = () => {
    const masq = this.cookies.get('MASQ');
    if (masq) {
      return true;
    }
    return false;
  };

  masqueradeEndtime = () => {
    const endTime = this.cookies.get('MASQ_END');
    if (endTime) {
      return moment.unix(endTime);
    }
    return undefined;
  };

  masqueradeTimeout = () => {
    const endTime = this.masqueradeEndtime();
    if (endTime) {
      return endTime.isBefore(moment());
    }
    return true;
  };

  onMasqueradeEnd = async () => {
    await this.masquerade();
    this.props.history.push('/masquerade-end');
  };

  getCurrentToken = () => {
    const masq = this.cookies.get('MASQ');
    if (masq) {
      // Check if we timed out while our UI was down
      if (this.masqueradeTimeout()) {
        this.cookies.remove('MASQ');
        this.cookies.remove('MASQ_FROM');
        this.cookies.remove('MASQ_END');
      } else {
        log.debug('current token is MASQ', masq);
        return masq;
      }
    }
    log.debug('current token is TOKEN', this.cookies.get('TOKEN'));
    return this.cookies.get('TOKEN');
  };

  updateUser = async (token, ttl, { noSetCookie } = {}) => {
    log.debug('updateUser', token);
    if (noSetCookie) {
      // Cookie already set, do not change expiry.
    } else if (token) {
      const options = getCookieOptions(ttl);
      log.debug('Set: TOKEN', token, options);
      this.cookies.set('TOKEN', token, options);
    } else {
      log.debug('removing cookies');
      this.cookies.remove('TOKEN');
    }
    // Get what the current token value should be
    // in case we still have MASQ set
    token = this.getCurrentToken();
    this.Api._updateInstanceToken(token);
    await this.setupUser(token);
    log.debug('Update User Complete');
  };

  refreshRates = async (currency = 'USD') => {
    // Always update USD since we use that in tons of places
    const { data: exchangeUsd } = await this.Api.getExchangeRates('USD');
    const exchange = { ...(this.state.exchange || {}), USD: exchangeUsd };
    // Also update a non-usd currency if requested
    if (currency !== 'USD') {
      const { data: currencyRates } = await this.Api.getExchangeRates(currency);
      exchange[currency] = currencyRates;
    }
    this.setState({ exchange });
    return exchange;
  };

  setupUser = async token => {
    log.trace('setupUser', token);
    let { lodgeMapping, exchange, user, customizations, client, currencies } = this.state;
    let agency;
    if (token) {
      try {
        // Always fetch the user information because we may be changing tokens
        // for example if we are masquerading, or the token may have expired
        const response = await this.Api.userInfo();
        user = response.data; // "global" let
        if (user) {
          // If we didn't get back an agent token then
          // somebody is doing something bad!
          if (user.principalType !== 'Agent') {
            log.error('Agent accessed with non-agent token:', user.userId, user.principalType);
            this.updateUser(null);
            return null;
          }
          // If we got a new token, bang it into place
          // to replace the permanent token we came in
          // with. This doesn't change our user.
          if (user.token) {
            this.Api._updateInstanceToken(token);
            this.cookies.set('TOKEN', user.token, getCookieOptions());
          }
          agency = getAgency(user.agency);

          FeatureFlags.setupFeatures({ agency: user.agency });

          customizations = get(user, 'agency', {});
          Sentry.configureScope(scope => {
            scope.setUser({ email: user.email });
            scope.setTag('agencyCode', get(user, 'agency.agencyCode'));
          });
        } else {
          throw new Error('User Token Invalid');
        }
        if (!lodgeMapping) {
          const response = await this.Api.getLodgeMappingPieces();
          lodgeMapping = response.data;
        }
        if (!exchange) {
          exchange = await this.refreshRates();
        }
        if (!currencies) {
          const response = await this.Api.getCurrencies();
          currencies = response.data;
        }
        await this.refreshClients();
      } catch {
        user = null;
        client = null;
        customizations = null;
        Sentry.configureScope(scope => {
          scope.setUser(null);
          scope.setTag('agencyCode', null);
        });
      }
    } else {
      user = null;
      client = null;
      customizations = null;
      FeatureFlags.reset();
      Sentry.configureScope(scope => {
        scope.setUser(null);
        scope.setTag('agencyCode', null);
      });
    }

    flushSync(() => this.setState({ lodgeMapping, exchange, user, customizations, client, currencies, agency })); // flushSync required
  };

  updateItineraries = (/* itinerary */) => {
    //    log.debug(`App: got updateItineraries`);
  };

  refreshClient = async clientId => {
    try {
      this.cookies.set('CLIENT_ID', clientId, getCookieOptions());
      const { data: client } = await this.Api.clientInfo(clientId);
      this.setState({ client: filter(client, c => c.id === clientId)[0] });
      //      log.debug(`App, refreshed client`);
    } catch {
      //      log.debug(`Error refreshing client`);
    }
  };

  refreshedItinerary = itinerary => {
    let found = false;
    const itineraries = reduce(
      this.state.itineraries,
      (ret, i) => {
        if (i.id === itinerary.id) {
          found = true;
          ret.push(itinerary);
        } else {
          ret.push(i);
        }
        return ret;
      },
      [],
    );
    if (!found) {
      itineraries.push(itinerary);
    }
    this.setState({ itineraries });
  };

  refreshItineraries = async clientId => {
    let result = [];
    try {
      const { data: itineraries } = await this.Api.getClientItineraries(clientId);
      result = itineraries;
      //      log.debug(`App, refreshed itineraries`, itineraries);
    } catch (err) {
      log.error(`Error refreshing itineraries`, err);
    } finally {
      this.setState({ itineraries: result });
      return result;
    }
  };

  addClient = newClient => {
    const clients = [...this.state.clients, newClient];
    this.setState({ clients });
  };

  clearClient = () => {
    this.setState({ client: null });
    this.cookies.remove('CLIENT_ID');
  };

  refreshClients = async () => {
    let clients = [];
    try {
      const response = await this.Api.agentGetClients(null, true);
      clients = get(response, 'data', []);
    } catch (err) {
      log.error('error fetching clients', err);
    }
    this.setState({ clients });
  };

  buildTheme = (customizations, initialWidth) => ({ theme: buildThemeWithProps(AGENT_THEME_DEFAULTS, initialWidth), customizations });

  onAppStart = async serverRender => {
    //    log.debug(`App.js, onAppStart, serverRender ${serverRender}, this.state: `,this.state);
    await FeatureFlags.loadConfig(this.Api);

    // If we are server rendering monkeypatch setState
    if (serverRender) {
      this.setState = newState => assign(this.state, newState);
    }

    // Must process token first
    const token = this.cookies.get('TOKEN');
    const masq = this.cookies.get('MASQ');
    if (token !== undefined && token !== 'undefined') {
      await this.updateUser(token, undefined, { noSetCookie: true });
    }
    if (masq !== undefined && masq !== 'undefined') {
      await this.masquerade(masq);
    }

    // Rebuild the theme & consolidate w/ customizations
    this.setState(this.buildTheme(this.state.customizations, this.state.initialWidth));

    try {
      // Then try to setup client
      const clientId = this.cookies.get('CLIENT_ID');
      if (clientId && clientId !== 'undefined') {
        const result = await this.Api.clientInfo(clientId);
        // If we didn't get the client we don't have access
        // or the client doesn't exist
        if (result) {
          await this.updateClient(result.data[0]);
        } else {
          log.debug('Clearing CLIENT_ID');
          this.cookies.remove('CLIENT_ID');
        }
      }
    } catch (err) {
      const error = new Error(`onAppStart, error during initial load: ${err}`);
      error.skipSentry = true; // Ignore for sentry
      log.error(error);
      log.debug('Clearing CLIENT_ID');
      this.cookies.remove('CLIENT_ID');
    }

    // Okay now we are ready to render
    this.setState({ renderReady: true });
  };

  refreshUser = async () => {
    const { data: user } = await this.Api.userInfo();
    this.setState({ user });
  };

  isSuperAdmin = () => {
    const isSuperAdmin = reduce(get(this.state.user, 'capabilities', []), (ret, cap) => ret || cap === 'superadmin', false);
    return isSuperAdmin;
  };

  isLeadAgent = () => {
    const isLeadAgent = get(this.state.user, 'agency.admins', []).includes(this.state.user.userId);
    return isLeadAgent;
  };

  constructor(props) {
    super(props);

    this.Api = get(props, 'api', new Api());
    this.cookies = get(props, 'cookies', new Cookies());

    if (props && props.initialState) {
      this.state = props.initialState;
      // Rebuild the theme
      assign(this.state, this.buildTheme(this.state.customizations, this.state.initialWidth));
    }

    // Always set the Api instance token before anything else renders
    const token = this.cookies.get('TOKEN');
    log.debug('token!', token);
    this.Api._updateInstanceToken(token);

    const masq = this.cookies.get('MASQ');
    const masqFromToken = this.cookies.get('MASQ_FROM');
    if (masq) {
      this.Api._masquerade(token, masqFromToken);
    }
  }

  async componentDidMount() {
    // Don't rerun app start in universal mode
    if (!this.state.renderReady) {
      await this.onAppStart(false);
    }
  }

  resetTheme = () =>
    this.setState({ theme: buildThemeWithProps(AGENT_THEME_DEFAULTS, this.state.initialWidth), customizations: undefined });

  setTheme = (path, color) => {
    // @todo: can probably translate path with key name from AGENT_DATA_TO_THEME_PATH for cleaner code
    // log.debug(`setting theme ${path}, ${color}`);
    const paths = { ...this.state.paths };
    set(paths, path, color);
    // @todo: we are manually slicing in palette here because just setting "typoGraphy" doesn't overwrite all the
    // keys for typography that `createMuiTheme` automagically creates. We will have to slice other things like
    // text color and button colors here as well (probably move to a const)
    const theme = buildThemeWithProps(
      merge({ palette: this.state.theme.palette, typography: { fontFamily: this.state.theme.typography.fontFamily } }, paths),
      this.state.initialWidth,
    );
    this.setState({ paths, theme });
  };

  setCustomization = (path, value, overrides = null) => {
    const _overrides = overrides ? overrides : { [path]: value };
    return this.setState({ customizations: { ...this.state.customizations, ..._overrides } });
  };

  getCampRegions = supplierCodes => {
    const { lodgeMapping } = this.state;
    return supplierCodes.reduce((acc, supplierCode) => {
      if (supplierCode in lodgeMapping.lodgeList) {
        const { regionCode } = lodgeMapping.lodgeList[supplierCode];
        if (regionCode in lodgeMapping.regionList) {
          acc.push([supplierCode, regionCode]);
        }
      }
      return acc;
    }, []);
  };

  logout = async () => {
    if (this.isMasquerading()) {
      // When masquerading a logout is masquerade end
      this.onMasqueradeEnd();
    } else {
      const res = await this.Api.agentLogout();
      if (res.status === 200 || res.status === 204) {
        this.updateUser(null);
        this.props.history.push('/login');
      }
    }
  };

  render() {
    const { theme, lodgeMapping, exchange, user, client, itineraries, customizations, renderReady, currencies, agency } = this.state;

    return (
      <div id="app" data-node-env={get(process.env, 'NODE_ENV', 'development')}>
        <GlobalStyles
          styles={{
            'html, body, #root, #app': {
              height: '100%',
            },
            'html *': {
              boxSizing: 'border-box',
            },
          }}
        />
        <ApiContext.Provider value={{ Api: this.Api }}>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === 'development' && window.localStorage.getItem('REACT_QUERY_DEBUG_TOOLS') === 'true' && (
              <ReactQueryDevtools /*(initialIsOpen={false}*/ />
            )}
            <LodgeMappingContext.Provider
              value={{
                lodgeMapping,
                exchange,
                refreshRates: this.refreshRates,
                getCampRegions: this.getCampRegions,
              }}
            >
              <UserContext.Provider
                value={{
                  app: 'agent',
                  agency,
                  user,
                  client,
                  clients: this.state.clients,
                  masqueradeEndtime: this.masqueradeEndtime,
                  onMasqueradeEnd: this.onMasqueradeEnd,
                  isMasquerading: this.isMasquerading,
                  masquerade: this.masquerade,
                  updateUser: this.updateUser,
                  refreshUser: this.refreshUser,
                  patchClient: this.patchClient,
                  updateClient: this.updateClient,
                  isSuperAdmin: this.isSuperAdmin,
                  isLeadAgent: this.isLeadAgent,
                  addClient: this.addClient,
                  refreshClient: this.refreshClient,
                  refreshClients: this.refreshClients,
                  selectClient: this.selectClient,
                  clearClient: this.clearClient,
                  logout: this.logout,
                }}
              >
                <CurrencyContextProvider currencies={currencies}>
                  <ItinerariesContext.Provider
                    value={{
                      itineraries,
                      refreshItineraries: this.refreshItineraries,
                      refreshedItinerary: this.refreshedItinerary,
                    }}
                  >
                    <FeatureProvider>
                      {renderReady === true ? (
                        <ThemeProvider
                          value={{
                            setTheme: this.setTheme,
                            setCustomization: this.setCustomization,
                            theme,
                            resetTheme: this.resetTheme,
                            customizations,
                          }}
                        >
                          <SnackbarProvider maxSnack={3} style={{ pointerEvents: 'auto' }}>
                            <ModalProvider>
                              <ItineraryBuilderStoreProvider>
                                <NiceModal.Provider>
                                  {!process.env.REACT_APP_DEV && process.env.REACT_APP_FRESHCHAT_TOKEN && <Freshchat />}
                                  <Routes />
                                </NiceModal.Provider>
                              </ItineraryBuilderStoreProvider>
                            </ModalProvider>
                          </SnackbarProvider>
                        </ThemeProvider>
                      ) : (
                        <Loading />
                      )}
                    </FeatureProvider>
                  </ItinerariesContext.Provider>
                </CurrencyContextProvider>
              </UserContext.Provider>
            </LodgeMappingContext.Provider>
          </QueryClientProvider>
        </ApiContext.Provider>
      </div>
    );
  }
}

let StyledApp = styled(App)(styles);

export default withRouter(StyledApp);
